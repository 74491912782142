<template>
  <div class="bodycon">
    <div>
      <h1 class="centext">保险经纪业务委托协议</h1>
      <p class="centext">(2020年1月版)</p>
    </div>
    <div class="pcen">
      <p>甲方(委托人)为依法成立的深圳市南华保险经纪有限公司。乙方(受托人)为注册本平台的个人(以下简称“您”)。依据《中华人民共和国合同法》、《中华人民共和国保险法》、《保险经纪人监管规定》等有关法律法规的规定,甲乙双方本着平等自愿、协商一致的原则,就甲方委托您为投保人或者被保险人订立投保方案的保险经纪业务事宜,达成本协议,以兹双方共同遵守。</p>
      <p><span>第一条</span>甲乙双方基于本合同形成委托代理关系,甲方委托您在授权的范围内从事保险经纪业务,并承担该行为产生的法律责任,您从从事约定的代理行为中获取甲方支付的服务费。</p>
      <p>本协议的订立并不直接或间接构成甲乙双方之间的劳动合同关系。</p>
      <p>甲方授权您代理的保险经纪业务为推荐或者解读保险产品或者方案。</p>
      <p><span>第二条</span>甲方依照上述法律法规及甲方的规章制度对您进行管理,您自愿遵守法律法规及甲方规章制度,并自愿接受甲方的监督和管理</p>
      <p><span>第三条</span>您作为甲方的受托人需要履行如下义务:</p>
      <p>(1)依照甲方的要求如实提供真实且准确的身份及从业及学历等个人经历信息;</p>
      <p>(2)接受并完成由甲方安排的各类保险专业及保险产品知识的培训;</p>
      <p>(3)您应根据法律法规要求向投保人介绍保险方案及产品,介绍要求准确、全面、与产品条款一致;</p>
      <p>(4)您在开展保险经纪业务的过程中,应保密投被保险人个人信息及甲方的商业秘密;</p>
      <p>(5)您应遵守甲方的规章制度及规章制度,包含但不限于业务流程、业务技能考核等；</p>
      <p>(6)您自行聘用他人协助管理的,应明确非甲方招聘行为,自行承担个人业务活动而产生的费用;</p>
      <p>(7)您的《保险经纪从业人员执业证书》仅限本人使用,不得借出、许可、转让转售给他人使用;</p>
      <p>(8)您在推荐产品或者方案时,需提醒投被保险人阅读责任免除、犹豫期、投保须知、条款等影响投保的内容,并提醒告知客户后续服务的热线或者方式</p>
      <p>(9)您在签订本协议前,存在与其他保险机构签订保险代理或者经纪聘用合同的需提前解除,否则因此给双方造成的损失由您承担。</p>
      <p><span>第四条</span>您作为甲方的受托人可行使如下权利:</p>
      <p><span>第五条</span>甲方作为依法成立的保险经纪公司及委托人,需要履行如下义务:</p>
      <p>(1)甲方应根据监管及自身业务的要求,为您提供岗前、上岗、日常培训支持并考核；</p>
      <p>(2)甲方应根据您提供的身份信息等资料,为您办理执业登记;</p>
      <p>(3)甲方应提供合法合规的与保险经纪业务相关保险产品或者服务相关的方案、产品介绍、保险条款、客户告知书、投保须知、保单样本、责任免除等</p>
      <p>(4)依据规章制度支付您相应的服务费;</p>
      <p>(5)甲方提供符合法律法规的投保系统或者流程;</p>
      <p>(6)甲方为投被保险人提供协助保险方案制定、保全、理赔、投诉处理等保险经纪行为；</p>
      <p><span>第六条</span>甲方作为委托人可行使如下权利</p>
      <p>(1)甲方有权依照规章制度对您的经纪业务活动进行监督、管理、检查</p>
      <p>(2)因您违反法律法规或者协议,给投被保险人或者甲方造成损失的,根据严重性,甲方有权暂停支付服务费或者解决本协议或者对您进行追偿；</p>
      <p>(3)甲方有权根据国家法律法规或市场变化、业务发展需要制定或者修改规章制度,您应遵照执行;</p>
      <p><span>第七条</span>本协议自双方签字盖章之日起生效,有效期三年,期满后甲乙双方任何一方未提出书面解约的,合同有效期自动顺延三年,可多次顺延。直至出现法定解除条件或者约定解除条件或者其中一方提出书面解除通知之日起终止。</p>
      <p>投保人保费根据法律法规规定及流程的设定直接支付至甲方的保费专用账户。</p>
      <p><span>第八条</span>关于您的禁止性行为</p>
      <p>(1)欺骗投保人、被保险人或者受益人</p>
      <p>(2)对投保人隐瞒与保险合同有关的重要情况;</p>
      <p>(3)阻碍投保人履行本法规定的如实告知义务,或者诱导其不履行本法规定的如实告知义务;</p>
      <p>(4)给予或者承诺给予投保人、被保险人、受益人保险合同约定以外的保险费回扣或者其他利益;</p>
      <p>(5)参与保险欺诈;</p>
      <p>(6)挪用、截留、侵占、代收保险费;</p>
      <p>(7)泄露在业务活动中知悉的投保人、被保险人的商业秘密;</p>
      <p>(8)代客户填写投保信息、健康告知或者其他影响承保结果的行为;</p>
      <p>(9)延迟上报出险情况的;</p>
      <p>(10)擅自制作与保险产品及服务相关的宣传资料的;</p>
      <p>(11)超过甲方授权的范围以甲方名义进行招聘、理赔承诺等行为的;</p>
      <p>(12)不如实告知客户与投保相关的重要事项,进行夸大或者不如实宣传的;</p>
      <p>(13)其他故意损害投被保险人和甲方利益的行为;</p>
      <p>(14)串通包括但不限于客户或者甲方内部工作人员以及其他外部人员,损害甲方利益的行为</p>
      <p><span>第九条</span>违约约定</p>
      <p>(1)甲乙双方均应严格遵守本协议,任何一方方违反协议约定的,守约方均有权要求违约方承担相应的违约责任；</p>
      <p>2)您擅自收取保费的,应及时缴纳;您滞留保费超过七日或者经过甲方两次以上提醒仍不缴纳的,甲方有权追扣相应保费的利息,涉及犯罪的移交司法机关处理;涉及投被保险人保险利益的,依法追究相应的责任;</p>
      <p>(3)您违反保密、知识产权等本协议其他约定的,甲方有权解除本协议并要求其赔偿相应的直接经济损失。</p>
      <p><span>第十条</span>保密约定</p>
      <p>(1)除非本协议另有约定,未经另一方事先书面同意,任何一方不得向第三方(有关法律、法规、政府部门或其他监管机构要求和双方的法律、会计人员除外)泄露收集的客户信息和甲方的商业秘密其中客户信息包含但不限于姓名、证件及号码、联系方式及地址、病历资料投保保障信息等;甲方的商业秘密包含但不限于甲方相关管理制度、经营信息技术信息等等。</p>
      <p>(2)双方在本协议下的保密义务为双方应承担的永久义务,该义务不因本协议终止而失效</p>
      <p><span>第十一条</span>合同解除</p>
      <p>(1)法定解除:期满未有顺延的、您身故或者被宣告失踪或者死亡的。</p>
      <p>(2)约定解除:双方协商一致。</p>
      <p>(3)单方解除:任何一方违反本协议约定的,守约方均有权解除本协议,协议解除时由甲方出具书面的解除证明。</p>
      <p><span>第十二条</span>协议终止后</p>
      <p>(1)您停止相应的保险经纪业务行为,并上交执业证及任何从甲方处领取的单证或者宣传资料或者资产。</p>
      <p>(2)甲乙双方将相关服务费核对并结算。</p>
      <p>(3)因您违约或者不遵守法律法规导致投被保险人或者甲方损失的,甲方有权追偿,并不因本协议的终止而终止。</p>
      <p><span>第十三条</span>知识产权</p>
      <p>您在开展业务期间应当合理利用甲方及甲方关联公司的商标及标识(以上合称甲方商标”),不得将甲方商标用于本协议外的其他目的,其他目的包括但不限于以甲方商标为字号注册企业或打着甲方的旗号欺诈消费者等。</p>
      <p><span>第十四条</span>其他</p>
      <p>(1)本协议若为线上签订,您勾选同意即视为已答订,签订起始日期为勾选同意日期。若为线下签订,生效日期则以您签订日期为准。</p>
      <p>(2)本协议未尽事宜,甲乙双方可通过补充协议的形式进行约定,补充协议与本协议具有同等的效力。</p>
      <p>(3)执行本协议发生争执的,由双方协商解决,协商不成的,任何一方均有权向合同签订地有管辖权的人民法院起诉。</p>
      <p>本协议签订地址:深圳市罗湖区</p>
      <h1 class="centext">保险承诺书</h1>
      <p>本人承诺,不存在如下情形,并知悉若虚假告知,深圳市南华保险经纪有限公司(以下简称“南华经纪”)可解除与本人的《保险经纪业务委托协议》,并注销为本人办理的保险经纪从业人员执业登记。若不实告知导致客户或者南华经纪利益遭受损失的,均由本人承担相应的责任。</p>
      <p>1、贪污、贿赂、侵占财产、挪用财产或者破坏社会主义市场经济秩序,被判处刑罚,执行期满未逾5年</p>
      <p>2、被金融监管机构决定在一定期限内禁止进入金融行业,期限未满;</p>
      <p>3、因严重失信行为被国家有关单位确定为失信联合惩戒对象且应当在保险领域受到相应惩戒;</p>
      <p>4、最近5年内具有其他严重失信不良记录。</p>
    </div>
  </div>
</template>
<script>
export default {

}
</script>
<style scoped>
  .bodycon{
    line-height: 70px;
    padding-bottom: 200px;
  }
  h1{
    font-size: 45px;
  }
  .pcen p{
    text-indent:2em;
  }
  .pcen span{
    padding: 0 10px;
    font-weight: 700;
    font-size: 38px;
  }
  .centext{
    text-align: center;
  }
</style>
